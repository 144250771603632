import React, { createContext, useState, useContext } from 'react';
const AuthContext = createContext();
const AuthProvider = ({ children }) => {
    const token = localStorage.getItem('token');
    const [isAuthenticated, setIsAuthenticated] = useState(token ? true : false);
    const login = async (username, password) => {
        // Implement your login logic here
        return await fetch('https://api.jannekedegraaff.nl/api/todoen/auth/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            username,
            password
        }),
        })
        .then((response) => response.json())
        .then((data) => { 

            if (data.message) {
               alert(data.message);
            } else {
                localStorage.setItem('token', JSON.stringify(data.token));
                localStorage.setItem('user', JSON.stringify(data.user));
                setIsAuthenticated(true);
                return data.user;
            }
        })
        .catch((error) => {
            console.error(error);
        });
    };
    const logout = () => {
        // Implement your logout logic here
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setIsAuthenticated(false);
    };
    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
        {children}
        </AuthContext.Provider>
    );
    };
const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
export { AuthProvider, useAuth };