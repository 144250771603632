import React, { useEffect, useState } from 'react';
import { useAuth } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';
import "../Login/login.css";

const AlertIcon = () => (
    <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={16} height={16}><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480L40 480c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
)

const Signup = () => {
  const { signUp } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [inputValues, setInputValues] = useState({
    username: '',
    password: '',
    passwordRepeat: ''
  })
  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true);
    
  };

  const checkPasswordMatch = () => {
    if (inputValues.password && inputValues.passwordRepeat && inputValues.password !== inputValues.passwordRepeat.value) {
        setPasswordMatch(false);
    } else {
        setPasswordMatch(true);
    }
  }

  const handleFormChange = async (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value })
  };

  useEffect(() => {
    checkPasswordMatch()
  }, [inputValues])

  if (loading) {
    return <div>Loading....</div>
  }
  return (
    <div className='page-container'> 
        <div id="auth-form">
            <h1>Sign up</h1>
            <form onSubmit={handleSignup} onChange={(e) => handleFormChange(e)}>
                <label htmlFor="username">Username:</label>
                <input type="text" id="username" name="username" />
                <label htmlFor="password">Password:</label>
                <input type="password" id="password" name="password" />
                <label htmlFor="passwordRepeat">Repeat password:</label>
                <input type="password" id="passwordRepeat" name="passwordRepeat" />
                {!passwordMatch && <div className="error"><AlertIcon/>Passwords do not match</div>}
                <input type="submit" value="Submit" />
            </form>
        </div>
        <a href='/login'>Already have an account? Log in here</a>
    </div>
  );
};
export default Signup;