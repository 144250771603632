import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./home.css";
import List from './List';
import AddListModal from '../../components/AddListModal';
import { fetchLists, fetchTasks, deleteListFromId, addTaskToList, deleteTaskFromId, updateTask } from '../../services/api';
// import Confirm from 'react-confirm';

const Home = () => {
  const [lists, setLists] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [selectedList, setSelectedList] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const [showAddListModal, setShowAddListModal] = useState(false);

  const loadData = async () => {
    const listsData = await fetchLists();
    setLists(listsData);

    const tasksData = await fetchTasks();
    setTasks(tasksData);
    return { listsData, tasksData };
  };

  useEffect(() => {
    loadData().then((r) => setSelectedList(r.listsData[1]));
  }, []);

  const addTask = async () => {
    const inputField = document.getElementById('task-name');
    const taskName = inputField.value;
    const body = {
      list_id: selectedList?.list_id,
      parent_task_id: null,
      task_name: taskName,
      due_date: null,
      priority: 'low',
      status: 'pending',
      notes: null
    };
    await addTaskToList(body).then((r) => {
      loadData();
      inputField.value = '';
    });
  }

  const deleteTask = async (task_id) => {
    await deleteTaskFromId(task_id).then((r) => {
      loadData();
    })
  }

  const handleStatusChangeTask = async (e, task) => {
    const { list_id, parent_task_id, task_name, due_date, priority, status, notes } = task;
    const body = {
      list_id,
      parent_task_id,
      task_name,
      due_date,
      priority,
      status: e.target.checked ? 'completed' : 'pending',
      notes
    }
    await updateTask(body, task.task_id).then(() => loadData());
  }

  const addSubtask = async (e, taskId) => {

    const taskName = e.target.value;
    const body = {
      list_id: selectedList?.list_id,
      parent_task_id: taskId,
      task_name: taskName,
      due_date: null,
      priority: 'low',
      status: 'pending',
      notes: null
    };
    await addTaskToList(body).then(() => {
      const inputField = document.getElementById(`add-subtask-input-${taskId}`);
      inputField.value = '';
      loadData();
    });
    // try {
    //   const response = await axios.post('https://api.jannekedegraaff.nl/api/todoen/tasks', body);
    //   if (response.status === 201) {
    //     fetchTasks();
    //   }
    // } catch (error) {
    //   console.error('Error adding task:', error);
    // }
   
    
  }

  const deleteList = async (listId) => {
    await deleteListFromId(listId).then(() => loadData());
  }


  return (
    <div className="page-container auth">
     <AddListModal show={showAddListModal} onClose={() => setShowAddListModal(false)} loadData={loadData}/>
      <h1>
        {selectedList?.list_name || 'No list selected'}
        <span id="remove-list-btn" style={{marginLeft: '10px'}} onClick={() => window.confirm('Are you sure you want to delete this list?') && deleteList(selectedList?.list_id)}>
          <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
      </h1>
      <p style={{paddingTop: '8px'}}>{selectedList?.description}</p>
      <nav class="sidenav">
        <h6 style={{paddingLeft: '20px', color: 'white', paddingBottom: '10px'}}>My lists:</h6>
        <hr style={{border: '1px solid white'}}/>
        <ul class="main-buttons">
          {lists.map((list, index) => {
            return <li className={list.list_id === selectedList?.list_id ? 'selected' : ''} key={index} onClick={() => setSelectedList(list)}>{list.list_name}</li>
          })}
          <li className="add-list" onClick={() => setShowAddListModal(true)}>+</li>
        </ul>
      </nav>
      <table id="add-table">
        <div className='input-box'>
          <input type="text" id="task-name" placeholder='Add new task' onKeyPress={(e) => e.key === 'Enter' && addTask()}></input>
          <span class="prefix">
          <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 7L4 7" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M15 12L4 12" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M9 17H4" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
          </span>
          <button onClick={() => addTask()}>Add</button>
        </div>
       
        {tasks.map((task, index) => {
          if (task.list_id === selectedList?.list_id && task.parent_task_id === null) {
            return (
              <div className='row'>
                <tr key={index} className="main-row"  onClick={() => setExpandedRow(expandedRow !==  task.task_id ? task.task_id : null)}>
                  <td>
                    <label className='checkmark-container'>
                      <input id={`task-input-${task.task_id}`} type="checkbox" checked={task.status === 'completed'} onChange={(e) => handleStatusChangeTask(e, task)}/>
                      <span className="checkmark"></span>
                    </label>
                  </td>
                  <td><p className={task.status === 'completed' ? 'completed' : ''}>{task.task_name}</p></td>
                  <td></td>
                  <td>
                  <div class="dropdown">
                    <div class="dropbtn">
                      <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 12H18.01M12 12H12.01M6 12H6.01M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12ZM19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12ZM7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <div class="dropdown-content">
                    <div onClick={() => deleteTask(task.task_id)}>
                      <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    {/* <a href="#">Link 2</a>
                    <a href="#">Link 3</a> */}
                    </div>
                  </div>
                    
                  </td>
                </tr>
                <tr id={`expandable-row-${task.task_id}`} className={`expandable-row ${expandedRow === task.task_id? 'expanded' : ''}`}>
                  <div className='subtasks-container'>
                    <ul>
                    {tasks.map((subtask, index) => {
                      if (subtask.parent_task_id === task.task_id) {
                        return <li>
                                  <div className='subtask-checkbox-container'>
                                    <input type='checkbox' checked={subtask.status === 'completed'} onChange={(e) => handleStatusChangeTask(e, subtask)}></input>
                                    <p className={subtask.status === 'completed' ? 'completed' : ''}>{subtask.task_name}</p>
                                  </div>
                                  <div class="dropdown">
                                    <div class="dropbtn">
                                      <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 12H18.01M12 12H12.01M6 12H6.01M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12ZM19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12ZM7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>
                                    </div>
                                    <div class="dropdown-content">
                                    <div onClick={() => deleteTask(subtask.task_id)}>
                                      <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>
                                    </div>
                                    {/* <a href="#">Link 2</a>
                                    <a href="#">Link 3</a> */}
                                    </div>
                                  </div>
                                 </li>
                      }
                    })}
                    </ul>
                  </div>
                  <td style={{width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                    <input class="subtask-input" text='text' id={`add-subtask-input-${task.task_id}`} placeholder="Add subtask" onKeyPress={(e) => e.key === 'Enter' && addSubtask(e, task.task_id)}></input>
                  </td>
                </tr>
              </div>
            );
          }
        })}
        {tasks.filter(task => task.list_id === selectedList?.list_id).length === 0 && <tr><td>No tasks found yet</td><td></td><td></td></tr>}
      </table>
      
      
    </div>
  );
};

export default Home;
