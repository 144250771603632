// Layout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import "./layout.css";

function Layout() {
  return (
    <div id="layout">
      <header>
        <h1></h1>
        <nav>
          <ul className='nav'>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </nav>
      </header>
      <main>
        <Outlet />
      </main>
    </div>
  );
}

export default Layout;