import axios from 'axios';
import './addlistmodal.css';

const AddListModal = ({show, onClose, loadData}) => {
    if (!show) {
        return null
    }

    const addList = (title, description) => {
        console.log(JSON.parse(localStorage.getItem('user')).user_id, title, description);
          const body = {
            user_id: JSON.parse(localStorage.getItem('user')).user_id, 
            list_name: title, 
            list_type: 'todo', 
            description: description, 
            color: '#E16339',
          };
          axios.post('https://api.jannekedegraaff.nl/api/todoen/lists', body).then((r) => {
            if (r.status === 201) {
                onClose();
                loadData();
            }
          });
      }

    const handleSubmit = (e) => {
        e.preventDefault();

        const title = e.target.title.value;
        const description = e.target.description.value;
        if (title && description) {
            addList(title, description);
            
        } else {
            alert('Please fill in all fields');
        }
    }

    return (
        <div id="add-list-modal" class="modal">
            <div class="modal-content">
                <h1>Add List</h1>
                <span class="close" onClick={() => onClose()}>&times;</span>
                <form id="add-list-form" class="add-list-form" onSubmit={handleSubmit}>
                    <label for="title">Title</label>
                    <input type="text" id="title" name="title" />
                    <label for="description">Description</label>
                    <input type="text" id="description" name="description" />
                    <button type="submit" value="Submit">Add list</button>
                </form>
            </div>
        </div>
    )

}

export default AddListModal