import React, { useState } from 'react';
import { useAuth } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';
import "./login.css";
import Loader from '../../components/loader';

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    const userData = await login(e.target.username.value, e.target.password.value);
    if (userData) {
      navigate('/home');
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader />
  }
  return (
    <div className='page-container'>
      <div id="auth-form">
        <h1>Login</h1>
        <form onSubmit={handleLogin}>
          <label htmlFor="username">Username:</label>
          <input type="text" id="username" name="username" />
          <label htmlFor="password">Password:</label>
          <input type="password" id="password" name="password" />
          <input type="submit" value="Submit" />
        </form>
      </div>
      <a href='/signup'>No account yet? Register here</a>
    </div>
  );
};
export default Login;