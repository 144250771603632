import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './routes/PrivateRoute';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import AuthRoute from './routes/AuthRoute';
import Signup from './pages/Signup/Signup';
import "./App.css";
import Layout from './layout/layout';
import './axiosConfig';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<Layout />}>
            <Route path="/login" element={<AuthRoute element={<Login />}/>} />
            <Route path="/signup" element={<AuthRoute element={<Signup />}/>} />
            <Route path="/home" element={<PrivateRoute element={<Home />} />} />
            <Route path="/" render={() => <div>404 Not Found</div>} element={<Navigate to="/home" replace />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}
export default App;