import axios from 'axios';

export const fetchLists = async () => {
  const user_id = JSON.parse(localStorage.getItem('user')).user_id;
  try {
    const response = await axios.get('https://api.jannekedegraaff.nl/api/todoen/lists', {
      params: { user_id: user_id}
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching lists:', error);
    throw error;
  }
};

export const fetchTasks = async () => {
  try {
    const response = await axios.get('https://api.jannekedegraaff.nl/api/todoen/tasks');
    return response.data;
  } catch (error) {
    console.error('Error fetching tasks:', error);
    throw error;
  }
};

export const deleteListFromId = async (listId) => {
  try {
    const response = await axios.delete(`https://api.jannekedegraaff.nl/api/todoen/lists/${listId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting list:', error);
    throw error;
  }
}

export const addTaskToList = async (body) => {
  try {
    const response = await axios.post('https://api.jannekedegraaff.nl/api/todoen/tasks', body);
    if (response.status === 201) {
      return response.data;
    }
  } catch (error) {
    console.error('Error adding task:', error);
  }
}

export const deleteTaskFromId =  async (task_id) => {
  try {
    const response = await axios.delete(`https://api.jannekedegraaff.nl/api/todoen/tasks/${task_id}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error('Error deleting task:', error);
  }
}

export const updateTask = async (body, taksId) => {
  try {
    const response = await axios.put(`https://api.jannekedegraaff.nl/api/todoen/tasks/${taksId}`, body).then((r) => r);
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error('Error changing status:', error);
  }
}